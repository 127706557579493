<template>
  <div style = "height:100%; background-color: #ffffff" class="d-flex justify-content-center align-items-center"> 
    <div class="container">
      <div id="passwordreset">

        <p class="logo"><a href="https://flowe.io"><img src="/static/img/flowe.png" class="imglogo"></a></p>

        <p class="text-center mt-4" v-if="tokeninvalid"><a :href="'/signin?redirect=' + redirecturl " class="text-primary">Sign in</a> to proceed.</p>

        <p class="text-center mt-4" v-if="!emailisvalid">We're sorry but this link has expired. Click <router-link :to="{path:'/profile'}" class="text-primary"> here</router-link> to go to your profile and start over.</p> 

      </div>
    </div>
  </div>
</template>	
<style type="text/css" scoped>
:root{

  /* --G700: #157815; */
  --N900: #313338;
}


html,
body {
  color: var(--N900);
  height: 100%;
}



h1, h2{ text-align: center; }

.pagetitle{
  width: 100%;
  margin: 0 auto;
  max-width: 400px;
  margin-bottom: 1rem;
  margin-top: 3%;
}


.form_wrapper{
  margin: 0 auto;
  max-width: 350px;
}

#signuppage .form_wrapper,
#passwordreset .form_wrapper{ 
  max-width: 355px; 
}

#signuppage2 .form_wrapper{ max-width: 400px; }

#responsepage .signuppage .pagetitle{
  max-width: 500px;
}

.btn-google img{ 
  width: 24px;
  height: 24px;
  margin-right:.3em; 
}

p.logo .imglogo{ 
  width: 200px; 
}
p.logo{
  text-align: center;
  width: 100%;
  margin: auto;
}
</style>
<script type="text/javascript"></script>
<script>

  import Signinapi from '@/services/api/signin'
  import Userapi from '@/services/api/users'
  import {bus} from '@/main'

  export default{
    name:'changeemail',
    data(){
     return{
      userid : null,
      status : "idle",
      emailisvalid : true,
      emailchanged : false,
      tokeninvalid : false,
      redirecturl : ""
    }
  },
  components:{},
  filters: {},
  mounted() {

    $('#preloader').addClass('hide')
  	this.checkifvalid()
    

  },
  methods: {

	checkifvalid( callback ){

	//var authid = this.$route.query.uid
	var emailid = this.$route.query.eid

	let self = this;

	Signinapi.Emailchangeconfirm( emailid )
	.then( function(response){


		if( response.data.ok ){
      setTimeout(function(){
        var str = 'Your email address has been changed.'
        var str1 = 'Your email was updated'
        bus.$emit('craeteaToast', {content:str,title:str1, variant:'success',append:true})

         if( localStorage.token != "" ){
        
          localStorage.emailchanged = "true"
          self.$router.push("/boards")

        
        }else{

          setTimeout( function(){
            self.$router.push("/")
          })


        }
      },200)
     



		}else{

			if( response.data.message == 'emailexpired' ){
				self.emailisvalid = false
			}
			if( response.data.message == 'tokeninvalid' ){
				self.tokeninvalid = true
			}
		}

	})
	.catch(error => console.log(error))
	.finally( () => {

		self.status = 'idle'

	})


	}
    }
   }
    </script>